import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from "@mui/system";
import { dateConvert } from "../constants/Helper";
import NotificationsDetail from "./NotificationsDetails";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import NoData from "./NoData";
import { appTheme } from "../theme/colorTheme";

const NotificationsAccordianScreen = (props) => {
  const ServicesRef = React.useRef(null);
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  

  React.useEffect(() => {
    
    !!props.notificationsData && props.notificationsData.length !== 0 && setExpanded(`${props.notificationsData[0].id}panel`)

  }, [props.notificationsData])

  return (
    <React.Fragment>
        {
        !!props?.notificationsData && props.notificationsData.length !== 0?
         props.notificationsData.map((item, i) => (
          <Accordion
            key={i}
            expanded={expanded === `${item?.id}panel`}
            onChange={handleChange(`${item?.id}panel`)}
            sx={{ bgcolor: appTheme.color11, mb: 1, padding: 0, borderRadius: 2 }}
            disableGutters={true}
            square={true}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: appTheme.color1 }} />}
              aria-controls="panel-content"
              id="panel-header"
              sx={{ padding: 0, pl: 1, pr: 1, width: "100%" }}
            >
              <Stack
                sx={{ color: appTheme.color1, width: '100%' }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>
                  <ListItem sx={{ padding: 0, width: "100%" }}>
                    <ListItemAvatar sx={{minWidth:'30px'}}>
                      {
                        item?.transaction?.status.toLocaleLowerCase() === 'rejected'?
                        <CancelRoundedIcon color="error"/>:item?.transaction?.status.toLocaleLowerCase() === 'pending'?
                        <WarningRoundedIcon color="warning"/>:<CheckCircleRoundedIcon color="success"/>
                      }
                    </ListItemAvatar>
                    <ListItemText sx={{ color: appTheme.color1, padding: 0 }} primary={
                      <Typography component="div" variant="caption" display="block" gutterBottom fontSize={11}>
                        {item?.message}
                      </Typography>
  
                    } secondary={
                      <Typography
                        display="block"
                        component="span"
                        variant="caption"
                        color={appTheme.color6}
                        fontSize={10}
                      >
                        {dateConvert(item?.transaction?.created_at)}
                      </Typography>
                    } />
                  </ListItem>
                </Box>
              </Stack>
            </AccordionSummary>
            <AccordionDetails ref={ServicesRef}>
              <NotificationsDetail itemList={item} viewTransactionDetails={props.viewTransactionDetails}/>
            </AccordionDetails>
          </Accordion>
        )):<NoData noDataText="You don't have any notifications yet." />
      }
      </React.Fragment>
  );
};

const NotificationsAccordian = React.memo(NotificationsAccordianScreen)

export default NotificationsAccordian;
