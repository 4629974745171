import { Box, CssBaseline, Skeleton, ThemeProvider } from "@mui/material";
import React from "react";
import PassbookCard from "../../layouts/PassbookCard";
import theme from "../../theme/theme";
import { Container } from "@mui/system";
import axiosService from "../../axios-api-kit/axiosService";
import { localStorageKey } from "../../constants/LocalStorageKey";
import { appTheme } from "../../theme/colorTheme";
import { useLocation, useNavigate } from "react-router-dom";

const Passbook = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [transactionsData, setTransactionsData] = React.useState(null);
  const getTransactionData = () => {
    axiosService.getTransactionDetails().then((response) => {
      setTransactionsData(response.data)
    }).catch(error => {
    })
  }
  React.useEffect(() => {
    if (location.key === 'default') {
      navigate('/');
    } else {
      sessionStorage.removeItem(localStorageKey.SETIDSTABSTATE)
      getTransactionData();
    }

  }, [location.key, navigate])
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container>
          {!!transactionsData ? <PassbookCard transactionsData={transactionsData} /> : 
          <Box sx={{ width: '100%' }}>
          <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
        </Box>
          }
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
};


export default Passbook;
