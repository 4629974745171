import React from "react";
import {
    ThemeProvider,
    CssBaseline,
    Typography,
    Button,
    Stack
} from "@mui/material";
import { Container, Box } from "@mui/system";
import theme from "../theme/theme";
import CustomizedInputs from "../components/customComponent/CustomInput";
import CheckboxLabels from "../components/customComponent/Checkbox";
import constant from "../constants/Constatnt";
import axiosService from "../axios-api-kit/axiosService";
import InformationDailog from "./InformationDailog";
import { localStorageKey } from "../constants/LocalStorageKey";
import { AuthContext } from "../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import { appTheme } from "../theme/colorTheme";

const IdDepositScreen = () => {
    const { setPaymentData, getDepositOrWithdrawIdData } = React.useContext(AuthContext);
    let navigate = useNavigate();
    const props = getDepositOrWithdrawIdData();
    const walletAmount = localStorage.getItem(localStorageKey.WALLETBALANCE);
    const [depositCoin, setDepositCoin] = React.useState(props?.website?.min_refill);
    const [isDepositCoinValid, setIsDepositCoinValid] = React.useState(true);
    const [finalAmount, setFinalAmount] = React.useState(depositCoin);
    const [isWalletCheckboxChecked, setIsWalletCheckboxChecked] = React.useState(null);
    const [continueButtonText, setContinueButtonText] = React.useState('Continue to Pay');
    const [walletBalanceUsed, setWalletBalanceUsed] = React.useState(null);

    const [openInfo, setOpenInfo] = React.useState(false);
    const [responseStatus, setResponseStatus] = React.useState('');
    const [statusMessage, setStatusMessage] = React.useState('');
    const [navigateUrl, setNavigateUrl] = React.useState('');
    const [buttonTextConfirm, setButtonTextConfirm] = React.useState('');
    const [buttonTextCancel, setButtonTextCancel] = React.useState('');

    const handleOpenDialog = (isDepositCoinValidCheck, depositCoinValueCheck) => {
        if (isDepositCoinValidCheck && depositCoinValueCheck.length !== 0 && depositCoinValueCheck !== '0') {
            setPaymentData({
                requested_amount: +depositCoin,
                payment_method_amount: finalAmount,
                user_website_id: props?.id,
                wallet_balance_used: +walletBalanceUsed,
                transactionFor: constant.transactionFor.idDeposit
            })
            navigate('/payment');
        }

    };

    const checkDepositCoin = React.useCallback((value) => {
        if (value.length <= 0 || value === '0' || +value < +props?.website?.min_refill) {
            setIsDepositCoinValid(false);
        } else {
            setIsDepositCoinValid(true);
        }
    }, [props.website.min_refill])

    const continueToPay = (depositCoinValueCheck) => {
        checkDepositCoin(depositCoinValueCheck);
        if (isWalletCheckboxChecked && +depositCoin <= +walletAmount) {
            if (isDepositCoinValid && depositCoinValueCheck.length !== 0 && depositCoinValueCheck !== '0') {
                const payload = new URLSearchParams()
                payload.append('user_website_id', props?.id)
                payload.append('requested_amount', +depositCoin)
                payload.append('wallet_balance_used', +walletBalanceUsed)
                axiosService.deposit_id_transaction(payload).then((res) => {
                    setResponseStatus('success');
                    setStatusMessage("Your request has been submitted successfully!");
                    setButtonTextConfirm('Ok')
                    setButtonTextCancel('Cancel')
                    setNavigateUrl('/');
                    setOpenInfo(true)
                })
                    .catch((error) => {
                        setResponseStatus('error');
                        setStatusMessage("Your request has been failed!");
                        setButtonTextConfirm('Ok')
                        setButtonTextCancel('Cancel')
                        setNavigateUrl('');
                        setOpenInfo(true)
                    })
            }
        } else {
            handleOpenDialog(isDepositCoinValid, depositCoinValueCheck);
        }

    }

    React.useEffect(() => {
        if (isWalletCheckboxChecked) {
            if (+depositCoin <= +walletAmount) {
                setContinueButtonText('Pay From Wallet');
                setWalletBalanceUsed(depositCoin);
                setFinalAmount(depositCoin);
            } else {
                setContinueButtonText('Continue to pay');
                setWalletBalanceUsed(walletAmount);
                setFinalAmount(Math.abs(depositCoin - walletAmount))
            }
        } else {
            setWalletBalanceUsed(0);
            setContinueButtonText('Continue to pay')
            setFinalAmount(depositCoin);
        }
    }, [depositCoin, isWalletCheckboxChecked, walletAmount])


    return (
        <React.Fragment>
            {!!openInfo ? <InformationDailog
                openInfo={openInfo}
                setOpenInfo={setOpenInfo}
                status={responseStatus}
                statusMessage={statusMessage}
                buttonTextConfirm={buttonTextConfirm}
                buttonTextCancel={buttonTextCancel}
                navigateUrl={navigateUrl} /> : ''}
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Container
                    sx={{
                        color: appTheme.color1,
                        mt: 1
                    }}
                >
                    <Stack
                        sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            mb: 3,
                        }}
                    >
                        <Box
                            sx={{
                                mb: 1,
                                width: 50,
                                height: 45,
                                borderRadius: 2,
                                backgroundColor: appTheme.color11,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <img
                                src={!!props?.website?.logo_url ? `${constant.baseUrl}/${props.website.logo_url}` : require('../img/no-pictures.png')}
                                width="40"
                                alt="logo"
                                loading="lazy"
                            />
                        </Box>

                        <Typography variant="body1" gutterBottom component="div">
                            {props?.website?.name}
                        </Typography>
                        <Typography variant="caption" display="block">
                            {props?.website?.access_url}
                        </Typography>
                        {/* <Typography variant="caption" display="block">
                            {props?.website?.demo_id}
                        </Typography> */}
                    </Stack>

                    <CustomizedInputs key={"1"} placeholder="Enter Coins"
                        label={`Coins* (Minimum deposit amount is ${props?.website?.min_refill} coins)`}
                        type="number"
                        defaultValue={depositCoin}
                        setData={setDepositCoin}
                        isValidation={isDepositCoinValid}
                        checkValidation={checkDepositCoin} />
                    <Stack
                        direction="row"
                        alignItems="flex-start"
                        justifyContent="left"
                        sx={{ mt: 3, mb: 3, border: `1px dashed ${appTheme.color17}`, padding: 1, opacity: !(walletAmount <= 0) ? 1 : .5 }}
                    >
                        <Box sx={{padding:1}}>
                        <CheckboxLabels disabled={!!(walletAmount <= 0)} onChange={(e, newValue) => {
                            setIsWalletCheckboxChecked(newValue);
                        }} />
                        </Box>
                        <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                            sx={{ color: appTheme.color7 }}
                        >
                            Pay From Wallet
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                sx={{ color: `white` }}
                            >
                                Current Wallet Balance {walletAmount}
                            </Typography>
                        </Typography>
                    </Stack>
                    <Stack spacing={2}>
                        <Button onClick={() => {
                            continueToPay(depositCoin);
                        }}
                            sx={{
                                color: appTheme.color0, bgcolor: appTheme.color12,
                                "&:hover": {
                                    bgcolor: appTheme.color12
                                },
                                focusVisible: false,
                                pb: 1, pt: 1
                            }}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                            >
                                {continueButtonText} ₹{finalAmount}
                            </Typography>
                        </Button>

                    </Stack>
                </Container>
            </ThemeProvider>
        </React.Fragment>
    );
};

const IdDeposit = React.memo(IdDepositScreen);

export default IdDeposit;
