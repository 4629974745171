import React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import constant from '../constants/Constatnt';
import axiosService from '../axios-api-kit/axiosService';
import { useNavigate } from 'react-router-dom';
import { Box, Stack } from '@mui/system';
import { Button } from '@mui/material';
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import { AuthContext } from '../context/AuthProvider';
import { appTheme } from '../theme/colorTheme';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import SnackBar from './SnackBar';

const TitlebarImageListScreen = (props) => {
  const navigate = useNavigate();
  const { setCreateIdData } = React.useContext(AuthContext)
  const [websiteData, setWebsiteData] = React.useState(null);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const getWebsiteData = () => {
    axiosService.getWebsitesData().then((response) => {
      const trimData = response.data.slice(0, 9)
      setWebsiteData(trimData)
    }).catch(error => {
    })
  }
  const handleClick = React.useCallback((message) => {
    setSnackBarMessage(message)
    setOpen(true);
}, []);
  React.useEffect(() => {
    getWebsiteData();
  }, []);
  return (
    <>
    <SnackBar
                setOpen={setOpen}
                open={open}
                message={snackBarMessage}
            />
        <Box>
        <Stack spacing={2}>
          <Button
            startIcon={<AddCircleSharpIcon />}
            sx={{
              color: appTheme.color0, bgcolor: appTheme.color12,
              "&:hover": {
                bgcolor: appTheme.color12
              },
              focusVisible: false,
              pb: 1, pt: 1
            }}
            onClick={() => {
              navigate('/ids?index=1')
            }}
          >
            Create New
          </Button>
        </Stack>
      </Box>
      {
        !!websiteData && <ImageList cols={3} gap={10}>
          {websiteData.map((item) => (
            <ImageListItem sx={{cursor:'pointer', opacity:item.is_create?'0.5':'1'}} key={item.id} onClick={() => {
              if(!item.is_create){
                setCreateIdData(item)
                navigate('/createId')
              }else{
                handleClick('Site Under Maintenance!');
              }
            }}>
              <img
                src={`${constant.baseUrl}/${item.logo_url}?w=164&h=164&fit=crop&auto=format`}
                srcSet={`${constant.baseUrl}/${item.logo_url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                alt={item.name}
                loading="lazy"
              />
              <ImageListItemBar

                subtitle={item.name}
                actionIcon={
                  <IconButton
                    sx={{ color: appTheme.color5 }}
                    aria-label={`info about ${item.name}`}
                  >
                    {
                      item.is_create?<ConstructionOutlinedIcon fontSize='small' sx={{bgcolor:appTheme.color10, borderRadius:50}} />:''
                    }
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      }
    </>





  );
}
const TitlebarImageList = React.memo(TitlebarImageListScreen)
export default TitlebarImageList;