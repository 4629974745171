import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Fab from "@mui/material/Fab";
import ArrowUpwardSharpIcon from "@mui/icons-material/ArrowUpwardSharp";
import ArrowDownwardSharpIcon from "@mui/icons-material/ArrowDownwardSharp";
import { styled } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import MenuConfiguration from "./MenuConfiguration";
import NoData from "./NoData";
import axiosService from "../axios-api-kit/axiosService";
import constant from "../constants/Constatnt";
import { AuthContext } from "../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import { ButtonGroup, Skeleton } from "@mui/material";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SnackBar from './SnackBar';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import InformationDailog from "./InformationDailog";
import { appTheme } from "../theme/colorTheme";
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: appTheme.color11,
  ...theme.typography.body2,
  padding: 0,
  textAlign: "center",
  color: appTheme.color1,
}));
const MyIdListScreen = ({ itemList, openDpositOrWithdrawDailog, handleClick, getSelectedId }) => {
  const openDpositOrWithdrawDailogEvent = (selectedId, openFor) => {
    openDpositOrWithdrawDailog(selectedId, openFor);
  }

  const selectedId = React.useCallback(() => {
    getSelectedId(itemList.id)
  }, [itemList.id, getSelectedId])
  return (
    <Item>
      <Stack
        sx={{ color: appTheme.color1, width: '100%' }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >

        <Box>
          <ListItem sx={{ padding: .5, pl: 1, pr: 1, width: "100%" }}>
            <ListItemAvatar>

              <img
                style={{
                  width: '45px', borderRadius: 50, backgroundColor: appTheme.color0, padding: 5, justifyContent: "center",
                  alignItems: "center", verticalAlign: 'middle'
                }}
                src={!!itemList?.website?.logo_url ? `${constant.baseUrl}${itemList.website.logo_url}` : require('../img/no-pictures.png')}
                alt="logo"
                loading="lazy"
              />

            </ListItemAvatar>
            <ListItemText
              primary={
                <>
                  <Typography
                    component="span"
                    variant="caption"
                    display="block"
                    fontSize={11}
                  >
                    {itemList?.website?.name}
                  </Typography>
                  <Typography
                    display="block"
                    component="span"
                    variant="caption"
                    gutterBottom
                    fontSize={10}>
                    <a href={`https://${itemList?.website?.access_url}`} target='blank' style={{ color: appTheme.color6, cursor: 'pointer', textDecoration: 'none' }}>
                      {itemList?.website?.access_url} <OpenInNewIcon fontSize="small" sx={{ fontSize: 14, verticalAlign: 'middle' }} />
                    </a>

                  </Typography>
                  <>
                    <Stack
                      sx={{ color: appTheme.color1, padding: 0 }}
                      direction="row"
                      alignItems="center"
                    >
                      <Person2RoundedIcon fontSize="small" sx={{ color: appTheme.color6, mr: .3 }} />
                      <Typography
                        display="block"
                        component="span"
                        variant="caption"
                        fontSize={10}
                        sx={{ color: appTheme.color6, }}>
                        ID: <CopyToClipboard text={itemList.username}>
                          <span style={{ cursor: 'pointer', fontWeight: 'bold', fontSize: 12 }} onClick={() => handleClick('Copied to clipboard!')}>
                            {itemList?.username}&nbsp;
                            <ContentCopyOutlinedIcon fontSize='small' sx={{ fontSize: 14, verticalAlign: 'middle' }} /></span>
                        </CopyToClipboard>
                      </Typography>

                    </Stack>

                  </>
                  <>
                    <Stack
                      sx={{ color: appTheme.color1, padding: 0, }}
                      direction="row"
                      alignItems="center"
                    >
                      <LockRoundedIcon fontSize="small" sx={{ color: appTheme.color6, mr: .3 }} />
                      <Typography
                        display="block"
                        component="span"
                        variant="caption"
                        fontSize={10}
                        sx={{ color: appTheme.color6 }}
                      >
                        Password: <CopyToClipboard text={itemList.password}>
                          <span style={{ cursor: 'pointer', fontWeight: 'bold', fontSize: 12 }} onClick={() => handleClick('Copied to clipboard!')}>
                            {itemList?.password}&nbsp;
                            <ContentCopyOutlinedIcon fontSize='small' sx={{ fontSize: 14, verticalAlign: 'middle' }} /></span>
                        </CopyToClipboard>
                      </Typography>
                    </Stack>
                  </>


                </>

              }

            />
          </ListItem>
        </Box>
        <Box>
          <MenuConfiguration 
          selectedId={selectedId} 
          isChangePasswordUnderMaintenance={itemList?.website?.is_change}
          handleClick={handleClick}
          />
        </Box>
      </Stack>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',

        }}
      >
        <ButtonGroup sx={{ width: '100%', border: 0, padding: 0, zIndex: 0 }} variant="text" aria-label="text button group">
          <Fab
            variant="extended"
            aria-label="add"
            size="small"
            sx={{
              opacity:itemList?.website?.is_deposit ? '0.5' : '1',
              pointerEvents:itemList?.website?.is_deposit ? 'none':'auto', 
              color: appTheme.color1,
              width: '50%',
              borderRadius: 0,
              borderBottomLeftRadius: 2,
              bgcolor: `${appTheme.color17}`,
              "&:hover": {
                bgcolor: `${appTheme.color17}`
              },
              textTransform: "capitalize",
              paddingRight: 2,
            }}
            onClick={() => {
              if (!itemList?.website?.is_deposit) {
                openDpositOrWithdrawDailogEvent(itemList, 'depositId');
              } else {
                handleClick('Site Under Maintenance!');
              }
            }}
          >
            <ArrowUpwardSharpIcon sx={{ color: appTheme.color15 }} />
            {
              itemList?.website?.is_deposit ? <>
              <Typography component="span" variant="caption" sx={{ fontSize: 10 }}>
                <span style={{color:appTheme.color1, fontWeight:'bolder', fontSize:9}}>Maintenance</span><br/>
                Deposit Id 
              </Typography><ConstructionOutlinedIcon fontSize="small" sx={{fontSize:15}}/>
              </>: 
              <Typography component="span" variant="caption" sx={{ fontSize: 10 }}>Deposit Id</Typography>
            }
          </Fab>
          <Fab
            variant="extended"
            aria-label="add"
            size="small"
            sx={{
              opacity: itemList?.website?.is_withdraw ? '0.5' : '1',
              pointerEvents:itemList?.website?.is_withdraw ? 'none':'auto', 
              color: appTheme.color1,
              width: '50%',
              borderRadius: 0,
              borderBottomRightRadius: 2,
              bgcolor: `${appTheme.color17}`,
              "&:hover": {
                bgcolor: `${appTheme.color17}`
              },
              textTransform: "capitalize",
              paddingRight: 2,
            }}
            onClick={() => {
              if(!itemList?.website?.is_withdraw){
                openDpositOrWithdrawDailogEvent(itemList, 'withdrawId');
              }else{
                handleClick('Site Under Maintenance!');
              }
              
            }}
          >
            <ArrowDownwardSharpIcon sx={{ color: appTheme.color13 }} />
            {
              itemList?.website?.is_withdraw ? <>
              <Typography component="span" variant="caption" sx={{ fontSize: 10 }}>
              <span style={{color:appTheme.color1, fontWeight:'bolder', fontSize:9}}>Maintenance</span><br/>
                Withdraw Id</Typography>
              <ConstructionOutlinedIcon fontSize="small" sx={{fontSize:15}}/>
              </>
              : 
              <Typography component="span" variant="caption" sx={{ fontSize: 10 }}>Withdraw Id</Typography>
            }
          </Fab>
        </ButtonGroup>
      </Box>
    </Item>

  );
};

const MyIdList = React.memo(MyIdListScreen)

const MyIdDetailCard = () => {
  const [open, setOpen] = React.useState(false);
  const [openInfo, setOpenInfo] = React.useState(false);
  const [responseStatus, setResponseStatus] = React.useState('');
  const [statusMessage, setStatusMessage] = React.useState('');
  const [navigateUrl, setNavigateUrl] = React.useState('');
  const [buttonTextConfirm, setButtonTextConfirm] = React.useState('');
  const [buttonTextCancel, setButtonTextCancel] = React.useState('');
  const { setDepositOrWithdrawIdData } = React.useContext(AuthContext);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  let navigate = useNavigate();
  const [myIdData, setMyIdData] = React.useState(null);
  const getIdData = () => {
    axiosService.getUserIds().then((response) => {
      setMyIdData(response.data)
    })
      .catch((error) => {
      })
  }

  const getSelectedId = React.useCallback((selectedId) => {
    const payload = new URLSearchParams();
    payload.append('user_website_id', selectedId);
    axiosService.channgeIdPassword(payload).then((response) => {
      setResponseStatus('success');
      setStatusMessage("Your request has been sucessfully placed !");
      setButtonTextConfirm('Ok')
      setButtonTextCancel('Cancel')
      setNavigateUrl('');
      setOpenInfo(true)
    }).catch((error) => {
      setResponseStatus('error');
      setStatusMessage("Your request has been failed !");
      setButtonTextConfirm('Ok')
      setButtonTextCancel('Cancel')
      setNavigateUrl('');
      setOpenInfo(true)
    })
  }, [])


  const openDpositOrWithdrawDailog = (selectedId, openForScreen) => {
    setDepositOrWithdrawIdData(selectedId);
    navigate(`/${openForScreen}`)
  }
  const handleClick = React.useCallback((message) => {
    setSnackBarMessage(message);
    setOpen(true);
  }, []);


  React.useEffect(() => {
    getIdData();
  }, []);
  return (
    <>
      {!!openInfo ? <InformationDailog
        openInfo={openInfo}
        setOpenInfo={setOpenInfo}
        status={responseStatus}
        statusMessage={statusMessage}
        buttonTextConfirm={buttonTextConfirm}
        buttonTextCancel={buttonTextCancel}
        navigateUrl={navigateUrl} /> : ''}
      <SnackBar
        setOpen={setOpen}
        open={open}
        message={snackBarMessage}
      />



      {
        !!myIdData ? <Stack spacing={1}>
          {
            myIdData.length !== 0 ? myIdData.map((item) => (
              <MyIdList itemList={item} key={item.id} openDpositOrWithdrawDailog={openDpositOrWithdrawDailog} handleClick={handleClick}
                getSelectedId={getSelectedId} />
            )) : <NoData noDataText="You don't have any ID yet." />
          }
        </Stack> : <Box sx={{ width: '100%' }}>
          <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
        </Box>
      }

    </>

  );
};

export default MyIdDetailCard;
