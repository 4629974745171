import React from "react";
import {
  CssBaseline,
  Grid,
  Paper,
  Skeleton,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import theme from "../../theme/theme";
import CarouselSlider from "../../layouts/Carousel";
import axiosService from "../../axios-api-kit/axiosService";
import { NavLink, useNavigate } from "react-router-dom";
import { localStorageKey } from "../../constants/LocalStorageKey";
import MyIdHomeDetailCard from "../../layouts/myIdsHome";
import ReactWhatsapp from "react-whatsapp";
import styled from "@emotion/styled";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { appTheme } from "../../theme/colorTheme";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: `transparent`,
  border: `1px solid ${appTheme.color17}`,
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: appTheme.color1,
}));
const Home = () => {
  const navigate = useNavigate();
  const [bannerData, setBannerData] = React.useState(null);
  const [helplineTitle, setHelplineTitle] = React.useState('');
  const [helplineContent, setHelplineTContent] = React.useState('');

  const getBannerData = React.useCallback(() => {
    axiosService.getBanners().then((resp) => {
      setBannerData(resp.data);
    }).catch((error) => {
    })
  }, [])

  const getConfigs = React.useCallback(() => {
    axiosService.getConfigs().then((response) => {
      setHelplineTitle(response.data[0].value.title);
      setHelplineTContent(response.data[0].value.content);
    }).catch((error) => {
    })
  }, [])


  React.useEffect(() => {
    sessionStorage.clear(localStorageKey.SETIDSTABSTATE);
    getBannerData();
    getConfigs();
  }, [getBannerData, getConfigs]);



  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container sx={{ color: appTheme.color1, paddingBottom: 2 }}>
          <Box sx={{ flexGrow: 1, mt: 1, backgroundColor: appTheme.color11, padding: 2, borderRadius: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={4}
                sx={{ textDecoration: 'none' }}
                component={NavLink}
                to="/walletDeposit"
                label="walletDeposit"
                value="walletDeposit">
                <Item>
                  <img
                    src={require(`../../img/deposit${appTheme.color12}.png`)}
                    width="25"
                    alt="logo"
                    loading="lazy"
                  />
                  <Typography variant="caption" display="block" gutterBottom fontSize={11}>
                    Deposit <br></br>Wallet
                  </Typography>
                </Item>
              </Grid>
              <Grid item xs={4}>
                <Item>
                  <Typography variant="body1" display="block" gutterBottom component="div">
                    <span style={{ color: appTheme.color6 }}>₹</span><span style={{ color: appTheme.color12 }}>{localStorage.getItem(localStorageKey.WALLETBALANCE)}</span>
                  </Typography>
                  <Typography variant="caption" display="block" gutterBottom fontSize={11}>
                    Wallet <br></br>Balance
                  </Typography>
                </Item>
              </Grid>
              <Grid item xs={4}
                sx={{ textDecoration: 'none' }}
                component={NavLink}
                to="/withdrawWallet"
                label="withdrawWallet"
                value="withdrawWallet">
                <Item>
                  <img
                    src={require(`../../img/withdraw${appTheme.color12}.png`)}
                    width="25"
                    alt="logo"
                    loading="lazy"
                  />
                  <Typography variant="caption" display="block" gutterBottom fontSize={11}>
                    Withdraw <br></br>Wallet
                  </Typography>
                </Item>

              </Grid>
            </Grid>
          </Box>
          <Box sx={{ mt: 1 }}>
            {
              !!bannerData ? <CarouselSlider data={bannerData} navigate={navigate} navigationEndpoint={'/ids?index=1'} /> :
                <Box sx={{ width: '100%' }}>
                  <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                  <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                  <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                  <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                  <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                  <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                  <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                  <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                </Box>
            }

          </Box>
          <Box>
            <MyIdHomeDetailCard />
          </Box>
          <Box>
            <Stack sx={{
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              mb: 2,
              mt: 2
            }}>
              {
                !!helplineContent && !!helplineTitle ?
                  <>
                    <Typography variant="caption" display="block" gutterBottom sx={{ color: appTheme.color12 }}>
                      Tips & Anouncements
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom>
                      {helplineTitle}<ReactWhatsapp number={`${helplineContent}`} message="" style={{
                        backgroundColor: appTheme.color12,
                        border: 0, borderRadius: 50, color: `black`, padding: '5px 10px', fontSize: 12, cursor: 'pointer'
                      }}>
                        <WhatsAppIcon fontSize="small" sx={{ verticalAlign: 'middle', fontSize: 18 }} /> {helplineContent}
                      </ReactWhatsapp>
                    </Typography>
                  </> : <Box sx={{ width: '100%' }}>
                    <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                    <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                    <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                  </Box>
              }
            </Stack>
          </Box>

        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default Home;
