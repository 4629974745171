import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Fab from "@mui/material/Fab";
import ArrowUpwardSharpIcon from "@mui/icons-material/ArrowUpwardSharp";
import ArrowDownwardSharpIcon from "@mui/icons-material/ArrowDownwardSharp";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import ButtonGroup from '@mui/material/ButtonGroup';
import axiosService from "../axios-api-kit/axiosService";
import constant from "../constants/Constatnt";
import { AuthContext } from "../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import BasicInfo from "./BasicInfo";
import TitlebarImageList from "./ImageList";
import { Button, Divider, IconButton, Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SnackBar from './SnackBar';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { appTheme } from "../theme/colorTheme";
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';

const MyIdHomeListScreen = (props) => {
    const openDepositOrWithdrawDailog = (selectedId, openFor) => {
        props.openDepositOrWithdrawDailog(selectedId, openFor);
    }
    return (

        <>
            <ListItem sx={{ padding: 1 }}>
                <ListItemAvatar>

                    <img
                        style={{
                            width: '45px', borderRadius: 50, backgroundColor: appTheme.color0, padding: 5, justifyContent: "center",
                            alignItems: "center", verticalAlign: 'middle'
                        }}
                        src={!!props.itemList?.website?.logo_url ? `${constant.baseUrl}${props.itemList.website.logo_url}` : require('../img/no-pictures.png')}
                        alt="logo"
                        loading="lazy"
                    />

                </ListItemAvatar>
                <ListItemText
                    primary={
                        <>
                            <Typography
                                component="span"
                                variant="caption"
                                display="block"
                            >
                                {props.itemList?.website?.name}
                            </Typography>
                            <Typography
                                display="block"
                                component="span"
                                variant="caption"
                                fontSize={10}
                                gutterBottom
                            >
                                <a href={`https://${props.itemList?.website?.access_url}`} target='blank' style={{ color: appTheme.color6, cursor: 'pointer', textDecoration: 'none' }}>
                                    {props.itemList?.website?.access_url} <OpenInNewIcon fontSize="small" sx={{ fontSize: 14, verticalAlign: 'middle' }} />
                                </a>
                            </Typography>
                            <>
                                <Stack
                                    sx={{ color: appTheme.color1, padding: 0 }}
                                    direction="row"
                                    alignItems="center"
                                >
                                    <Person2RoundedIcon fontSize="small" sx={{ color: appTheme.color6, mr: .3 }} />
                                    <Typography
                                        display="block"
                                        component="span"
                                        variant="caption"
                                        fontSize={10}
                                        sx={{ color: appTheme.color6 }}>
                                        ID: <CopyToClipboard text={props.itemList?.username}>
                                            <span style={{ cursor: 'pointer', fontWeight: 'bold', fontSize: 12 }} onClick={() => props.handleClick('Copied to clipboard!')}>
                                                {props.itemList?.username}&nbsp;
                                                <ContentCopyOutlinedIcon fontSize='small' sx={{ fontSize: 14, verticalAlign: 'middle' }} /></span>
                                        </CopyToClipboard>
                                    </Typography>

                                </Stack>

                            </>
                            <>
                                <Stack
                                    sx={{ color: appTheme.color1, padding: 0, }}
                                    direction="row"
                                    alignItems="center"
                                >
                                    <LockRoundedIcon fontSize="small" sx={{ color: appTheme.color6, mr: .3 }} />
                                    <Typography
                                        display="block"
                                        component="span"
                                        variant="caption"
                                        fontSize={10}
                                        sx={{ color: appTheme.color6 }}
                                    >
                                        Password: <CopyToClipboard text={props.itemList?.password}>
                                            <span style={{ cursor: 'pointer', fontWeight: 'bold', fontSize: 12 }} onClick={() => props.handleClick('Copied to clipboard!')}>
                                                {props.itemList?.password}&nbsp;
                                                <ContentCopyOutlinedIcon fontSize='small' sx={{ fontSize: 14, verticalAlign: 'middle' }} /></span>
                                        </CopyToClipboard>
                                    </Typography>
                                </Stack>
                            </>


                        </>

                    }

                />
            </ListItem>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',

                }}
            >
                <ButtonGroup sx={{ width: '100%', border: 0, padding: 0, zIndex: 0 }} variant="text" aria-label="text button group">
                    <Fab
                        variant="extended"
                        aria-label="add"
                        size="small"
                        sx={{
                            opacity: props?.itemList?.website?.is_deposit ? '0.5' : '1',
                            pointerEvents:props?.itemList?.website?.is_deposit ? 'none':'auto', 
                            width: '50%',
                            color: appTheme.color1,
                            borderRadius: 0,
                            borderBottomLeftRadius: 10,
                            bgcolor: `${appTheme.color17}`,
                            "&:hover": {
                                bgcolor: `${appTheme.color17}`
                            },
                            textTransform: "capitalize",
                            paddingRight: 2,
                        }}
                        onClick={() => {
                            if (!props?.itemList?.website?.is_deposit) {
                                openDepositOrWithdrawDailog(props.itemList, 'depositId')
                            } else {
                                props.handleClick('Site Under Maintenance!');
                            }

                        }}
                    >
                        <ArrowUpwardSharpIcon sx={{ color: appTheme.color15 }} />
                        {
                            props?.itemList?.website?.is_deposit ? <>
                                <Typography component="span" variant="caption" sx={{ fontSize: 10 }}>
                                    <span style={{ color: appTheme.color1, fontWeight: 'bolder', fontSize: 9 }}>Maintenance</span><br />
                                    Deposit Id
                                </Typography><ConstructionOutlinedIcon fontSize="small" sx={{ fontSize: 15 }} />
                            </> :
                                <Typography component="span" variant="caption" sx={{ fontSize: 10 }}>Deposit Id</Typography>
                        }
                    </Fab>
                    <Fab
                        variant="extended"
                        aria-label="add"
                        size="small"
                        sx={{
                            opacity: props?.itemList?.website?.is_withdraw ? '0.5' : '1',
                            pointerEvents:props?.itemList?.website?.is_withdraw ? 'none':'auto', 
                            width: '50%',
                            color: appTheme.color1,
                            borderRadius: 0,
                            borderBottomRightRadius: 10,
                            bgcolor: `${appTheme.color17}`,
                            "&:hover": {
                                bgcolor: `${appTheme.color17}`
                            },
                            textTransform: "capitalize",
                            paddingRight: 2,
                        }}
                        onClick={() => {
                            if (!props?.itemList?.website?.is_withdraw) {
                                openDepositOrWithdrawDailog(props.itemList, 'withdrawId')
                            } else {
                                props.handleClick('Site Under Maintenance!');
                            }

                        }}
                    >
                        <ArrowDownwardSharpIcon sx={{ color: appTheme.color13 }} />
                        {
                            props?.itemList?.website?.is_withdraw ? <>
                                <Typography component="span" variant="caption" sx={{ fontSize: 10 }}>
                                    <span style={{ color: appTheme.color1, fontWeight: 'bolder', fontSize: 9 }}>Maintenance</span><br />
                                    Withdraw Id</Typography>
                                <ConstructionOutlinedIcon fontSize="small" sx={{ fontSize: 15 }} />
                            </>
                                :
                                <Typography component="span" variant="caption" sx={{ fontSize: 10 }}>Withdraw Id</Typography>
                        }
                    </Fab>
                </ButtonGroup>
            </Box>
        </>




    );
};

const MyIdHomeList = React.memo(MyIdHomeListScreen)

const MyIdHomeDetailCard = () => {
    //const scollToRef = React.useRef(null);

    const [open, setOpen] = React.useState(false);
    const { setDepositOrWithdrawIdData } = React.useContext(AuthContext);
    const navigate = useNavigate();
    const [myIdData, setMyIdData] = React.useState(null);
    const [snackBarMessage, setSnackBarMessage] = React.useState('');
    const isMediumDevice = useMediaQuery('(min-width:550px)');
    const cardWidth = 281;

    const scrollIntoForWard = () => {
        const element = document.getElementById("scrollRef");
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        element.scrollLeft = element.scrollLeft + cardWidth;
    }
    const scrollIntoBackWard = () => {
        const element = document.getElementById("scrollRef");
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        element.scrollLeft = element.scrollLeft - cardWidth;
    }
    const getIdData = () => {
        axiosService.getUserIds().then((response) => {
            const trimData = response.data.slice(0, 6)
            setMyIdData(trimData)
        })
            .catch((error) => {
            })
    }

    const handleClick = React.useCallback((message) => {
        setSnackBarMessage(message)
        setOpen(true);
    }, []);

    const StyledIconButton = styled(IconButton)`
  height: 35px;
  width: 35px;
  position: absolute;
  border-radius: 16px;
  right:${(props) => (props?.isleft === "true" ? "none" : "0")};;
  left:${(props) => (props?.isleft === "true" ? "0" : "none")};
  top:35%;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  border-color: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.3);
  z-index: 1;
  opacity: 1;
  :hover {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px rgba(0, 0, 0, 0.5), 0px 1px 10px rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.5);
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
  }
`;


    const openDepositOrWithdrawDailog = (selectedId, openForScreen) => {
        setDepositOrWithdrawIdData(selectedId);
        navigate(`/${openForScreen}`)
    }

    React.useEffect(() => {
        getIdData();
    }, []);

    return (
        <>
            <SnackBar
                setOpen={setOpen}
                open={open}
                message={snackBarMessage}
            />
            {
                !!myIdData ?
                    !!myIdData && myIdData.length !== 0 ?
                        <>

                            <Stack
                                sx={{ color: appTheme.color1, width: '100%', mt: 3 }}
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Box>
                                    <Typography variant="body1" gutterBottom>
                                        My IDs
                                    </Typography>
                                    <Divider sx={{ borderTop: `1px solid ${appTheme.color12}`, width: "55px" }} />
                                </Box>
                                <Box>
                                    <Button
                                        startIcon={<AddCircleSharpIcon fontSize="small" />}
                                        onClick={() => {
                                            navigate('/ids?index=1')
                                        }}
                                        size="small"
                                        variant="contained"
                                        color="inherit"
                                        sx={{
                                            pr: .7,
                                            pl: .7,
                                            fontSize: 10,
                                            bgcolor: appTheme.color12,
                                            "&:hover": {
                                                bgcolor: appTheme.color12
                                            },
                                            color: appTheme.color0,
                                            mr: 1,
                                            focusVisible: false,
                                        }}
                                    >
                                        <Typography component="span" variant="caption">Create New</Typography>

                                    </Button>

                                    <Button
                                        onClick={() => {
                                            navigate('/ids?index=0')
                                        }}
                                        size="small"
                                        variant="contained"
                                        color="inherit"
                                        sx={{
                                            pr: .7,
                                            pl: .7,
                                            fontSize: 10,
                                            bgcolor: appTheme.color12,
                                            "&:hover": {
                                                bgcolor: appTheme.color12
                                            },
                                            color: appTheme.color0,
                                            mr: 0,
                                            focusVisible: false,
                                        }}
                                    >
                                        <Typography component="span" variant="caption">View All</Typography>

                                    </Button>

                                </Box>
                            </Stack>
                            <Box sx={{ position: 'relative' }}>
                                {
                                    (myIdData.length !== 1 || !isMediumDevice)&& <>
                                    <StyledIconButton isleft="false" onClick={() => {
                                    scrollIntoForWard();
                                }}>
                                    <ArrowForwardIosRoundedIcon />
                                </StyledIconButton>
                                <StyledIconButton isleft="true" onClick={() => {
                                    scrollIntoBackWard();
                                }}>
                                    <ArrowBackIosNewRoundedIcon />
                                </StyledIconButton>
                                    </>
                                }

                                <Box
                                    id="scrollRef" sx={{
                                        width: "100%", overflow: "auto", display: "flex", mt: 2, mb: 2,
                                        "&::-webkit-scrollbar": {
                                            display: "none"
                                        },
                                        scrollBehavior: 'smooth', transition: "transform 0.6s ease-in"
                                    }} >

                                    {
                                        myIdData.map((item) => (
                                            <div key={item.id} style={{
                                                marginRight: 16,
                                                marginLeft: 0,
                                                position: 'relative',
                                                zIndex: 0
                                            }}>
                                                <Paper style={{
                                                    width: `${cardWidth - 14}px`, transition: "transform 0.6s ease-in", backdropFilter: 'blur(40px)', backgroundColor: appTheme.color3, color: appTheme.color1, borderRadius: 10,
                                                }}>
                                                    <MyIdHomeList itemList={item} openDepositOrWithdrawDailog={openDepositOrWithdrawDailog} handleClick={handleClick} />
                                                </Paper>
                                                
                                            </div>
                                        ))
                                        
                                    }
                                    <div key="createNew" onClick={() => {
                                            navigate('/ids?index=1')
                                        }} style={{
                                        cursor:'pointer',
                                        marginLeft: 0,
                                        position: 'relative',
                                        zIndex: 0
                                    }}>
                                        <Paper style={{
                                            display:'flex',
                                            flexDirection:'row',
                                            justifyContent:'center',
                                            alignItems:'center',
                                            height:'138px',
                                            width: `${cardWidth - 14}px`, transition: "transform 0.6s ease-in", backdropFilter: 'blur(40px)', backgroundColor: appTheme.color3, color: appTheme.color1, borderRadius: 10,
                                        }}>
                                            <AddCircleSharpIcon fontSize="small" />&nbsp;<Typography component="span" variant="caption">Create New</Typography>
                                        </Paper>

                                    </div>
                                </Box>
                            </Box>
                        </>

                        : <Box>
                            <BasicInfo />
                            <TitlebarImageList />
                        </Box>

                    : <Box sx={{ width: '100%' }}>
                        <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                        <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                        <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                        <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                        <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                        <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                    </Box>
            }
        </>

    );
};

export default MyIdHomeDetailCard;
