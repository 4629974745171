export const localStorageKey = {
    APITOKEN: 'apiToken',
    WALLETBALANCE: 'walletBalance',
    USERNAME: 'userName',
    MOBILENUMBER: 'mobileNumber',
    CUSTOMERDATA: 'customerData',
    SIMNUMBER: 'simNumber',
    FIRSTNAME: 'firstName',
    LASTNAME: 'lastName',
    SELECTEDDOBDATE: 'selectedDobDate',
    EMAILID: 'emailId',
    SELECTEDPRODUCTPLAN: 'selectedProductPlan',
    PRODUCTOFFERS: 'productOffers',
    FLOWDATA: 'flowData',
    SERVERURLKEY: 'serverUrl',
    AVATAR: 'avatar',
    SETCREATEIDDATA: 'setCreateIdData',
    SETPAYMENTDATA: 'setPaymentData',
    SETDEPOSITORWITHDRAWIDDATA: 'setDepositOrWithdrawData',
    SETIDSTABSTATE: 'idTabState',
    USERID: 'userId',
    ISDEVTOOLOPEN:'isDevToolOpen'
}