
const constant = {
    websiteName:'RRRPLAY',
    baseUrl: 'https://rockbhai.com/',
    whatsAppNumber:'+919999994994',
    transactionType: {
        depositWallet: 'DepositWalletRequest',
        withdrawWallet: 'WithdrawWalletRequest',
        depositId: 'DepositIdRequest',
        withdrawId: 'WithdrawIdRequest',
        createId: 'CreateIdRequest',
        changeIdPasswordRequest: 'ChangeIdPasswordRequest'
    },
    transactionFor: {
        idDeposit: 'idDeposit',
        walletDeposit: 'walletDeposit',
        createIdDeposit: 'createIdDeposit',
        changePasswordRequest: 'changePasswordRequest'
    },
    accountType: {
        savings: 'savings',
        current: 'current'
    }
}

export default constant;