import React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { styled, useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Header from "./Header";
import { Stack, Typography } from "@mui/material";
import constant from "../constants/Constatnt";
import { localStorageKey } from "../constants/LocalStorageKey";

//import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
//import VolunteerActivismRoundedIcon from '@mui/icons-material/VolunteerActivismRounded';
import LocalPoliceRoundedIcon from '@mui/icons-material/LocalPoliceRounded';
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
//import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { AuthContext } from "../context/AuthProvider";
import InformationDailog from "./InformationDailog";
import { useLocation, useNavigate } from "react-router-dom";
import { appTheme } from "../theme/colorTheme";


const drawerWidth = 240;
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
const ResponsiveDrawer = (props) => {
  const location = useLocation();
  let navigate = useNavigate()
  const { signOut } = React.useContext(AuthContext)
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openInfo, setOpenInfo] = React.useState(false);
  const [responseStatus, setResponseStatus] = React.useState('');
  const [statusMessage, setStatusMessage] = React.useState('');
  const [navigateUrl, setNavigateUrl] = React.useState('');
  const [buttonTextConfirm, setButtonTextConfirm] = React.useState('');
  const [buttonTextCancel, setButtonTextCancel] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const selectedAction = (selectedMenu) => {
    if (selectedMenu === 'Logout') {
      setResponseStatus('warning');
      setStatusMessage("Are you sure want to Logout ?");
      setButtonTextConfirm('Yes')
      setButtonTextCancel('Cancel')
      setNavigateUrl('');
      setOpenInfo(true)
    }else if(selectedMenu === 'Withdrawal Details'){
      navigate('/withdrawDetails')
    }else if(selectedMenu === 'Create Id'){
      navigate('/ids?index=1')
    }else if(selectedMenu === 'Notifications'){
      navigate('/notifications')
    }else if(selectedMenu === 'Terms & Conditions'){
      navigate('/termsNconditions')
    }
  }

  React.useEffect(() => {
    if(!!currentPath && currentPath !== location.pathname){
      if(mobileOpen){
        setMobileOpen(false);
      }
    }
    setCurrentPath(location.pathname)
   
  }, [location.pathname, mobileOpen, currentPath])

  const confirmationIsValid = React.useCallback(() => {
    signOut();
  }, [signOut])



  const drawer = (
    <>
      {!!openInfo ? <InformationDailog
        openInfo={openInfo}
        setOpenInfo={setOpenInfo}
        status={responseStatus}
        statusMessage={statusMessage}
        confirmation={confirmationIsValid}
        buttonTextConfirm={buttonTextConfirm}
        buttonTextCancel={buttonTextCancel}
        navigateUrl={navigateUrl} /> : ''}
      <DrawerHeader sx={{ bgcolor: `black`, color: appTheme.color1, justifyContent: 'flex-start' }}>

        <Stack sx={{ width: "100%" }}
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between">
          <Box sx={{ padding: 2 }}>
            <img alt="avatar" src={!!localStorage.getItem(localStorageKey.AVATAR) ? `${constant.baseUrl}/${localStorage.getItem(localStorageKey.AVATAR)}` :
              require('../img/defaultAvatar.jpeg')}
              style={{ width: '80px', borderRadius: 100 }} />
              <Typography variant="caption" component="div">{localStorage.getItem(localStorageKey.USERNAME)}</Typography>
            <Typography variant="caption" component="div">+91 {localStorage.getItem(localStorageKey.MOBILENUMBER)}</Typography>
            <Divider sx={{
              borderTop: '1px solid #fff'
            }} />
            <Typography variant="caption" component="div" sx={{ color: appTheme.color12, fontWeight: 600 }}>Wallet Balance ₹{localStorage.getItem(localStorageKey.WALLETBALANCE)}</Typography>
          </Box>

          <IconButton onClick={handleDrawerToggle} onKeyDown={handleDrawerToggle} sx={{ color: appTheme.color1 }}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </Stack>

      </DrawerHeader>
      <Divider />
      <List onClick={handleDrawerToggle} onKeyDown={handleDrawerToggle}>
        {[
          // ["Profile", <AccountBoxRoundedIcon />],
          ["Withdrawal Details", <AccountBalanceRoundedIcon fontSize="small"/>],
          ["Create Id", <PersonAddAlt1RoundedIcon fontSize="small"/>],
          // ["Refer & Earn", <VolunteerActivismRoundedIcon />],
          ["Terms & Conditions", <LocalPoliceRoundedIcon fontSize="small"/>]
        ].map((item, index) => (
          <ListItem key={item[0]} disablePadding onClick={() => {
            selectedAction(item[0])
          }}>
            <ListItemButton>
              <ListItemIcon>
                {item[1]}
              </ListItemIcon>
              <ListItemText primary={
                <>
                <Typography variant="caption" component="div">
                {item[0]}
                  </Typography>
                  </>
              } />
            </ListItemButton>
            <Divider />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List onClick={handleDrawerToggle} onKeyDown={handleDrawerToggle}>
        {[
          ["Notifications", <NotificationsNoneRoundedIcon fontSize="small"/>],
          // ["Help", <HelpOutlineRoundedIcon />],
          ["Logout", <LogoutRoundedIcon fontSize="small"/>]].map((item, index) => (
            <ListItem key={item[0]} disablePadding onClick={() => {
              selectedAction(item[0])
            }}>
              <ListItemButton>
                <ListItemIcon>
                  {item[1]}
                </ListItemIcon>
                <ListItemText primary={
                  <>
                  <Typography variant="caption" component="div">
                  {item[0]}
                    </Typography>
                    </>
                } />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Header openDrawewr={handleDrawerToggle} />
      <Box sx={{ width: 250 }} role="presentation">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

export default ResponsiveDrawer;
