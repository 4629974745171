import React from "react";
import {
  ThemeProvider,
  CssBaseline,
  Button,
  Stack
} from "@mui/material";
import { Container, Box } from "@mui/system";
import { phoneNumberValidation } from "../../constants/Helper";
import theme from "../../theme/theme";
import CustomizedInputs from "../customComponent/CustomInput";
import FullScreenDialog from "../../layouts/FullScreenDailog";
import LoginWithPasswordScreen from "./loginWithpassword";
import InputAdornment from '@mui/material/InputAdornment';
import axiosService from "../../axios-api-kit/axiosService";
import RegisterScreen from "./register";
import { localStorageKey } from "../../constants/LocalStorageKey";
import { appTheme } from "../../theme/colorTheme";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthProvider";

const LoginScreen = () => {
  let navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [isPhoneNumberValid, setIsPhoneNumberValid] = React.useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [isUserRegistered, SetIsUserRegistered] = React.useState(false);
  const [dailogHeaderText, SetDailogHeaderText] = React.useState("");
  const { getDevToolState } = React.useContext(AuthContext);


  const handleOpenDialog = (isPhoneNumberValidCheck, phoneNumberCheck) => {
    if (isPhoneNumberValidCheck && phoneNumberCheck.length !== 0) {
      const payload = new URLSearchParams()
      payload.append('phone', phoneNumberCheck)
      axiosService.checkIsUserRegisterd(payload).then((resp) => {
        if (resp?.data?.is_registered) {
          SetIsUserRegistered(true);
          SetDailogHeaderText("Login")
        } else {
          SetIsUserRegistered(false);
          SetDailogHeaderText("Register")
        }
        setOpenDialog(true);
      })
        .catch((error) => {
          setOpenDialog(false);
        })

    }

  };
  const openLoginWithPassword = React.useCallback((inputData) => {

    if (isUserRegistered) {
      return <LoginWithPasswordScreen data={inputData} />
    } else {
      return <RegisterScreen data={inputData} />
    }

  }, [isUserRegistered])

  const checkUserNameValidation = (value) => {
    if (phoneNumberValidation(value)) {
      setIsPhoneNumberValid(true)
    } else {
      setIsPhoneNumberValid(false)
    }
  }


  const continueToLogin = (phoneNumberCheck) => {
    checkUserNameValidation(phoneNumberCheck);
    handleOpenDialog(isPhoneNumberValid, phoneNumberCheck);
  }


  React.useEffect(() => {
    sessionStorage.clear(localStorageKey.SETIDSTABSTATE);
  }, [])


  return (
    <React.Fragment>
      {!!openDialog ? <FullScreenDialog openDialog={openDialog} setOpenDialog={setOpenDialog} headerText={dailogHeaderText} layout={() => openLoginWithPassword({
        enteredMobileNumber: phoneNumber
      }
      )} /> : ''}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container
          sx={{
            color: appTheme.color1,
            mt: 10
          }}
        >
          <Stack
            sx={{
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              mb: 10,
            }}
          >
            <Box
              sx={{
                mb: 1,
                width: 50,
                height: 45,
                borderRadius: 2,
                backgroundColor: appTheme.color11,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  width: '120px', borderRadius: 5, backgroundColor: appTheme.color11, padding: 10,
                  justifyContent: "center", alignItems: "center", verticalAlign: 'middle'
                }}
                src={require('../../img/logo.jpeg')} alt="logo"></img>
            </Box>

          </Stack>


          <CustomizedInputs key={"2"} placeholder="Enter 10 Digits Phone Number"
            label="Phone Number*" defaultValue={phoneNumber}
            type="number"
            setData={setPhoneNumber}
            isValidation={isPhoneNumberValid}
            checkValidation={checkUserNameValidation}
            startAdornment={
              <InputAdornment position="start" sx={{ color: appTheme.color1 }}>
                <img
                  src={require("../../img/icons8-india-48.png")}
                  width="30"
                  alt="logo"
                  loading="lazy"
                />&nbsp;+91
              </InputAdornment>
            }
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                if(!getDevToolState()){
                  continueToLogin(phoneNumber);
                }else{
                  navigate('/devTools')
                }
              }
            }} />

          <Stack>
            <Button onClick={() => {
              if(!getDevToolState()){
                continueToLogin(phoneNumber);
              }else{
                navigate('/devTools')
              }
              
            }}
              sx={{
                color: appTheme.color0, bgcolor: appTheme.color12,
                "&:hover": {
                  bgcolor: appTheme.color12
                },
                focusVisible: false,
                pb: 1, pt: 1
              }}>Submit</Button>

          </Stack>
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default LoginScreen;
