import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CheckboxLabels from '../components/customComponent/Checkbox';
import constant from '../constants/Constatnt';
import { Typography } from '@mui/material';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { appTheme } from '../theme/colorTheme';

const PaymentModeScreen = (props) => {
  const [selectedPaymentType, setSelectedPaymentType] = React.useState([])


  return (
    <React.Fragment>
      <TableContainer sx={{ bgcolor: appTheme.color0, pl:1, pr:1 }}>
        <Table sx={{ color: appTheme.color1 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: appTheme.color1, textAlign:'left', margin:0, padding:0 }}>
                <Typography variant="caption" display="block" gutterBottom>Mode</Typography>
              </TableCell>
              <TableCell sx={{ color: appTheme.color1, }}>
                <Typography variant="caption" display="block" gutterBottom>Display Name</Typography>
              </TableCell>
              <TableCell sx={{ color: appTheme.color1 }} align="right">
                <Typography variant="caption" display="block" gutterBottom>UPI Details</Typography>
              </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row, i) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, color: appTheme.color1 }}
              >
                <TableCell padding="checkbox" sx={{width:5}}>
                  <CheckboxLabels checked={selectedPaymentType?.id === row?.id} onChange={(e, newValue) => {
                    if (newValue) {
                      setSelectedPaymentType(row);
                      props.handleChecked(row, newValue);
                    } else {
                      setSelectedPaymentType(null)
                      props.handleChecked(null, newValue);
                    }
                  }} />
                </TableCell>
                <TableCell sx={{ color: appTheme.color1, width:150}} component="th" scope="row">
                  <img
                    src={!!row?.logo_url ? `${constant.baseUrl}/${row.logo_url}` : require('../img/no-pictures.png')}
                    style={{ verticalAlign: 'middle', borderRadius: 50, width: "30px" }}

                    alt="logo"
                    loading="lazy"
                  />&nbsp; <Typography variant="caption" gutterBottom fontSize={11}>{row.name}</Typography>
                </TableCell>
                <TableCell sx={{ color: appTheme.color1 }} align="right">
                  <Typography variant="caption" display="block" gutterBottom fontSize={11}>
                  <CopyToClipboard text={row.account_identifier}>
                          <span style={{ cursor: 'pointer', fontWeight:'bold', fontSize:11 }} onClick={props.handleClick}>
                          {row.account_identifier}&nbsp;
                            <ContentCopyOutlinedIcon fontSize='small' sx={{ fontSize: 14, verticalAlign: 'middle' }} /></span>
                        </CopyToClipboard>
                  </Typography>

                </TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>

  )
}

const PaymentMode = React.memo(PaymentModeScreen)

export default PaymentMode;

