import React from "react";

import { nameValidation, accountNumberValidation, ifscCodeValidation } from "../constants/Helper";
import { styled } from "@mui/material/styles";
import axiosService from "../axios-api-kit/axiosService";
import CustomizedInputs from "../components/customComponent/CustomInput";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Button, FormHelperText, MenuItem, Paper, Select, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles"
import { Box } from "@mui/system";
import { BankList } from "../bankDetails";
import constant from "../constants/Constatnt";
import { localStorageKey } from "../constants/LocalStorageKey";
import { appTheme } from "../theme/colorTheme";

const useStyles = makeStyles((theme) => ({
    inputRoot: {
        borderRadius: 2
    },
    clearIndicator: {
        color: appTheme.color1
    }
}));

const AddAccountForm = (props) => {
    const classes = useStyles();
    const CustomTextField = styled(TextField)({
        "& label.Mui-focused": {
            color: appTheme.color11,
        },
        "&& input.Mui-disabled": {
            color: appTheme.color10,
            textFillColor: appTheme.color10
        },
        "& .MuiSvgIcon-root": {
            color: appTheme.color1,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: appTheme.color11,
        },
        "& input::placeholder": {
            fontSize: 12
        },
        "& .MuiOutlinedInput-root": {
            fontSize: "1em",
            borderRadius: 3,
            height: 40,
            paddingTop: 2,
            "& fieldset": {
                borderColor: appTheme.color11,
            },
            "&:hover fieldset": {
                borderColor: appTheme.color11,
            },
            "&.Mui-focused fieldset": {
                borderColor: appTheme.color11,
            },
            "&.MuiOutlinedInput-root": {
                color: appTheme.color1,
                backgroundColor: appTheme.color11
            },
        },
    });

    const [accountNumber, setaccountNumber] = React.useState('');
    const [isaccountNumberValid, setIsaccountNumberValid] = React.useState(true);
    const [ifscCode, setIfscCode] = React.useState('');
    const [isifscCodeValid, setIsifscCodeValid] = React.useState(true);
    const [accounHolderName, setAccounHolderName] = React.useState('');
    const [isAccountHolderNameValid, setIsAccountHolderNameValid] = React.useState(true);
    const [bankNameValue, setBankNameValue] = React.useState(null)
    const [isBankNameValid, setIsBankNameValid] = React.useState(true);
    const [accountType, setAccountType] = React.useState('');
    const [isAccountTypeSelected, setIsAccountTypeSelected] = React.useState(true);
    const [isSubmitClicked, setIsSubmitButtonClicked] = React.useState(true);

    const getAccountType = React.useCallback((value) => {
        if (!!value) {
            return constant.accountType.current;
        } else {
            return constant.accountType.savings;
        }
    }, [])

    const getIsCurrentAccount = React.useCallback((value) => {
        if (value === constant.accountType.savings) {
            return false;
        } else {
            return true;
        }
    }, [])

    const isFormValid = React.useCallback((isifscCodeValid, isaccountNumberValid, isAccountHolderNameValid,
        ifscCodeCheck, accountNumberCheck, accounHolderNameCheck) => {
        if (isaccountNumberValid &&
            accountNumberCheck.length !== 0 &&
            isifscCodeValid &&
            ifscCodeCheck.length !== 0 &&
            isAccountHolderNameValid &&
            accounHolderNameCheck.length !== 0 &&
            accountType.length !== 0 &&
            isAccountTypeSelected &&
            !!bankNameValue && !!isBankNameValid
        ) {
            if (props.isBankDetailsUpdate) {
                if (accounHolderName === props.selectedAccount.account_display_name &&
                    accountNumber === props.selectedAccount.account_identifier &&
                    bankNameValue === props.selectedAccount.bank_name &&
                    ifscCode === props.selectedAccount.ifsc_code &&
                    accountType === getAccountType(props.selectedAccount.is_current_account)) {
                    props.handleClose();
                } else {
                    setIsSubmitButtonClicked(false);
                    const payload = new URLSearchParams()
                    payload.append('account_display_name', accounHolderName);
                    payload.append('account_identifier', accountNumber);
                    payload.append('bank_name', bankNameValue);
                    payload.append('ifsc_code', ifscCode);
                    payload.append('is_current_account', getIsCurrentAccount(accountType));
                    axiosService.getUpdateMethodTypes(props.selectedAccount.id, payload).then((response) => {
                        props.getData();
                        props.handleClose();
                        setIsSubmitButtonClicked(true);
                    }).catch(error => {
                        setIsSubmitButtonClicked(true);
                    })
                }

            } else {
                setIsSubmitButtonClicked(false);
                const payload = new URLSearchParams()
                payload.append('account_display_name', accounHolderName);
                payload.append('account_identifier', accountNumber);
                payload.append('bank_name', bankNameValue);
                payload.append('ifsc_code', ifscCode);
                payload.append('is_current_account', getIsCurrentAccount(accountType));
                payload.append('payment_method_type_id', props?.selectedAccount?.id)
                payload.append('user_id', localStorage.getItem(localStorageKey.USERID))
                axiosService.addMethodTypes(payload).then((response) => {
                    props.getData();
                    props.handleClose();
                    setIsSubmitButtonClicked(true);
                }).catch(error => {
                    setIsSubmitButtonClicked(true);
                })
            }


        }

    }, [accounHolderName, accountNumber, bankNameValue, ifscCode, isBankNameValid, accountType, getIsCurrentAccount, isAccountTypeSelected, props, getAccountType]);


    const checkifscCodeValidation = React.useCallback((value) => {
        setIfscCode(value.toUpperCase())
        if (ifscCodeValidation(value.toUpperCase())) {
            setIsifscCodeValid(true)
        } else {
            setIsifscCodeValid(false)
        }
    }, [])

    const checkaccountNumberValidation = React.useCallback((value) => {
        if (accountNumberValidation(value)) {
            setIsaccountNumberValid(true)
        } else {
            setIsaccountNumberValid(false)
        }
    }, [])

    const checkIfAccountNameValidation = React.useCallback((value) => {
        if (nameValidation(value)) {
            setIsAccountHolderNameValid(true)
        } else {
            setIsAccountHolderNameValid(false)
        }
    }, [])


    const addDetails = (bankNameValueCheck, ifscCodeCheck, accountNumberCheck, accounHolderNameCheck, accountTypeCheck) => {
        !!bankNameValueCheck ? setIsBankNameValid(true) : setIsBankNameValid(false);
        checkifscCodeValidation(ifscCodeCheck);
        checkaccountNumberValidation(accountNumberCheck);
        checkIfAccountNameValidation(accounHolderNameCheck);
        if (!(accountTypeCheck.length !== 0)) {
            setIsAccountTypeSelected(false)
        }
        isFormValid(isifscCodeValid, isaccountNumberValid, isAccountHolderNameValid, ifscCodeCheck, accountNumberCheck, accounHolderNameCheck);
    }

    React.useEffect(() => {
        if (!!props?.selectedAccount?.payment_method_type_id) {
            if (!!props.selectedAccount.is_current_account) {
                setAccountType(constant.accountType.current)
            } else {
                setAccountType(constant.accountType.savings)
            }
        }
        if(!!props?.selectedAccount?.account_identifier){
            setaccountNumber(props.selectedAccount.account_identifier);
        }
        if(!!props?.selectedAccount?.ifsc_code){
            setIfscCode(props.selectedAccount.ifsc_code)
        }
        if(!!props?.selectedAccount?.account_display_name){
            setAccounHolderName(props.selectedAccount.account_display_name)
        }
        if(!!props?.selectedAccount?.bank_name){
            setBankNameValue(props.selectedAccount.bank_name)
        }
    }, [props])


    return (
        <React.Fragment>
            <Typography variant="caption" gutterBottom sx={{ color: appTheme.color8 }}>
                Bank Name*
            </Typography>
            <Box sx={{ mt: .5 }}>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    onChange={(event, value) => {
                        setBankNameValue(value);
                        !!value ? setIsBankNameValid(true) : setIsBankNameValid(false)
                    }}
                    value={bankNameValue}
                    options={BankList}
                    classes={classes}
                    isOptionEqualToValue={(option, value) => option === value}
                    PaperComponent={({ children }) => (
                        <Paper style={{ background: appTheme.color1, width: '100%', fontSize: 12 }}>{children}</Paper>
                    )}
                    renderInput={(params) => <CustomTextField
                        error={!isBankNameValid}
                        helperText={!isBankNameValid ? 'Incorrect entry.' : ''}
                        {...params}
                        placeholder="Select Bank" sx={{
                            color: appTheme.color1,
                            borderRadius: 5,
                            mb: 2
                        }} />}
                />
            </Box>

            <CustomizedInputs key={"1"} placeholder="Enter Account Number"
                label="Account Number*" defaultValue={accountNumber}
                type="number"
                setData={setaccountNumber}
                isValidation={isaccountNumberValid}
                checkValidation={checkaccountNumberValidation}
            />
            <CustomizedInputs key={"2"} placeholder="Enter IFSC Code"
                label="IFSC Code*" defaultValue={ifscCode}
                setData={setIfscCode}
                isValidation={isifscCodeValid}
                checkValidation={checkifscCodeValidation} />
            <CustomizedInputs key={"3"} placeholder="Enter Account Holder Name"
                label="Account Holder Name*" defaultValue={accounHolderName}
                setData={setAccounHolderName}
                isValidation={isAccountHolderNameValid}
                checkValidation={checkIfAccountNameValidation} />
            <Typography variant="caption" gutterBottom sx={{ color: appTheme.color8 }}>
                Select Account Type*
            </Typography>
            <Box sx={{ mt: .5 }}>
                <Select
                    error={!isAccountTypeSelected}
                    sx={{
                        height: 40, width: '100%',
                        borderColor: appTheme.color11,
                        backgroundColor: appTheme.color11,
                        fontSize: "1em",
                        color: `white`,
                        "& .MuiSvgIcon-root": {
                            color: appTheme.color1,
                        },
                        '&:not(.Mui-disabled):hover::before': {
                            borderColor: appTheme.color11,
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: appTheme.color11,
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: appTheme.color11,
                        },

                    }} value={accountType}
                    onChange={(event) => {
                        setAccountType(event.target.value);
                        if (!!event.target.value) {
                            setIsAccountTypeSelected(true);
                        }

                    }}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem value="">
                        <span style={{ color: appTheme.color10, fontSize: 12 }}>Select account type</span>
                    </MenuItem>
                    <MenuItem value={constant.accountType.savings} sx={{ fontSize: 12 }}>Savings</MenuItem>
                    <MenuItem value={constant.accountType.current} sx={{ fontSize: 12 }}>Current</MenuItem>
                </Select>
                {
                    !isAccountTypeSelected ? <FormHelperText sx={{ color: appTheme.color2, pl: 2 }}>Incorrect entry.</FormHelperText> : ''
                }

            </Box>

            <Stack spacing={2} sx={{
                mt: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <Button sx={{
                    fontSize: 11,
                    color: appTheme.color0, bgcolor: appTheme.color12,
                    "&:hover": {
                        bgcolor: appTheme.color12
                    },
                    focusVisible: false
                }} onClick={() => {
                    if(isSubmitClicked){
                        addDetails(bankNameValue, ifscCode, accountNumber, accounHolderName, accountType)
                    }
                    
                }}>{
                        props.isBankDetailsUpdate ? 'Save' : 'Add'
                    }</Button>
            </Stack>

        </React.Fragment>
    );
};

const AddAccountFormScreen = React.memo(AddAccountForm);
export default AddAccountFormScreen;
